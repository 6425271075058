import { useNavigate } from "react-router-dom"
import { App } from "../types/app"

type Props = {
  app: App
}

export const AppItem: React.FC<Props> = ({ app }) => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    if (app.name === 'ApoCampus') {
      navigate('/apocampus')
    } else 
    navigate(`/app/${app.link}`)
  }

  return (
    <div className="app-item">
      <div className="app-item-info">
        <div>{app.name}</div>
        <div>{app?.version[0]?.version}</div>
      </div>
      <div className="app-item-image">
        <img src={app.imgPath} alt='Trainings Apps' className='app-item-image-icon'/>
      </div>
      <button 
        className="app-item-button"
        onClick={handleButtonClick}
      >
        Team auswählen &#x2192;
      </button>
    </div>
  )
}
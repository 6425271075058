import { gql } from 'graphql-request';

export const categories = gql`
  query {
    categories {
      id
      name
      apps {
        id
        name
        imgPath
        link
        version {
          id
          name
          version
          size
          publishedOn
          qrCodeImgPath
          linkDownload
        } 
      }
    }
  }
`
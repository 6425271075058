import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import MainLayout from '../components/MainLayout'
import { NavWrapper } from '../components/NavWrapper'
import { AppsList } from './AppsList'
import { AppItemDetails } from '../components/AppItemDetails'
import { ApoCampusApp } from '../components/ApoCampus'

const Pages: React.FC = () => (
  <NavWrapper>
    <Routes>
    <Route element={<Navigate to="/" />} />
    <Route
      path={'/'}
      element={
        <MainLayout>
          <React.Suspense>
            <AppsList />
          </React.Suspense>
        </MainLayout>
      }
    />
    <Route
      path="/app/:link"
      element={
        <MainLayout>
          <React.Suspense>
            <AppItemDetails />
          </React.Suspense>
        </MainLayout>
      }
    />
        <Route
      path="/apocampus"
      element={
        <MainLayout>
          <React.Suspense>
            <ApoCampusApp />
          </React.Suspense>
        </MainLayout>
      }
    />
    </Routes>
    </NavWrapper>
    )

    export default Pages
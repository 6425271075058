import React, { FunctionComponent, useContext, createContext, useState, ReactNode } from 'react'

export type NavBarProps = {
  logoUrl: string
  logoUrlSmall: string
  logoAlt: string
  children?: React.ReactNode
}

interface INavContext {
  navExpanded: boolean
  toggleNav: () => void
}

const NavContext = createContext<INavContext | null>(null)

export function NavWrapper({ children }: { children: ReactNode }) {
  const [navExpanded, setNavExpanded] = useState<boolean>(false)
  const toggleNav = () => {
    setNavExpanded((prev) => !prev)
  }
  return <NavContext.Provider value={{ navExpanded, toggleNav }}>{children}</NavContext.Provider>
}

export const useNavProvider = (): INavContext => {
  const navContext = useContext(NavContext)
  if (!navContext) {
    throw new Error('Navwrapper is needed to use the hook')
  }
  return navContext
}

const NavBar: FunctionComponent<NavBarProps> = ({ logoUrl, logoAlt }) => {
  return (
    <div className="nav">
    <div className="nav-header">
      <div className="nav-header-container">
        <div 
          className='cursor-pointer sm:hidden'
        >
        </div>
        <div className="nav-logo-container">
          <img className="nav-logo" src={`${logoUrl}`} alt={`${logoAlt}`} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default NavBar

import { useQuery } from "react-query"
import { useGqlClient } from "../client.js"
import { app } from "../queries/app.js"
import { App } from "../../types/app.ts"

interface Props {
  id: string
}

export const useAppsQuery = (props: Props) => {
  const gqlClient = useGqlClient()
  const variables = { id: props.id }
  return useQuery(
    ['app', props.id],
    async () => {
      const data = await gqlClient.request<{ app: App }>(app, variables)
      return data?.app || []
    },
    { enabled: !!props.id, staleTime: Infinity, ...props },
  )
}
import { Category } from '../types/categories';
import { useCategoriesQuery } from '../graphql/hooks/categories'
import { CategoryAppsItem } from './CategoryAppsItem';

export const CategoryAppsList: React.FC = () => {
  const { data: categories } = useCategoriesQuery()
  return (
    <div className='category-list-container'>
      {categories?.map((category: Category) => (
        <CategoryAppsItem 
          key={category.id}
          category={category}
      />
      ))}
    </div>
  )
}
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import './styles/main.scss'
import Pages from './pages/Pages'
import { BrowserRouter as Router } from 'react-router-dom'
import { secondsToMilliseconds } from 'date-fns'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: secondsToMilliseconds(60), // The minimum time before data is refetched
        notifyOnChangeProps: 'tracked',
      },
    },
  })

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Pages />
        </Router>
      </QueryClientProvider>
    </div>
  )
}

export default App;

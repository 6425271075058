import { App } from '../types/app'
import trainingsIcon from '../assets/add.png'
import otherAppsIcom from '../assets/app-store.png'
import { AppItem } from './AppItem'
import { Category } from '../types/categories'

type Props = {
  category: Category
}

export const CategoryAppsItem: React.FC<Props>= ({ category }) => {
  const icon = category.name === 'Schulungsapp' ? trainingsIcon : otherAppsIcom
  return (
    <div className="category-list">
      <div className="category-list-title">
        <img src={icon} alt="Trainings Apps" />
        <span>{category.name}</span>
      </div>
      <div className="category-list-items">
        {category.apps.map((app: App) => (
          <AppItem key={app.id} app={app} />
        ))}
      </div>
    </div>
  )
}
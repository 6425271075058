import { useQuery } from "react-query"
import { useGqlClient } from "../client.js"
import { categories } from "../queries/categories.js"
import { Category } from "../../types/categories.ts"

export const useCategoriesQuery = (options?: any) => {
  const gqlClient = useGqlClient()
  return useQuery(
    'categories', 
    async () => {
      const data = await gqlClient.request<{ categories: Category[] }>(categories)
      return data?.categories || []
    },
    { enabled: true, staleTime: Infinity, ...options },
  )
}
import NavBar from './NavWrapper'
import React, { ReactNode } from 'react'
import logoWhite from '../assets/logoWhite.svg'

interface MainLayoutProps {
    children: ReactNode;
  }

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {

  return (
    <div className="layout">
      <>
        <NavBar 
          logoUrl={logoWhite} 
          logoUrlSmall={logoWhite} 
          logoAlt="CRM Logo" 
        >
        </NavBar>
      </>
      <div className="layout-content">
        {children}
      </div>
      <div className="layout-footer">
        <div className="layout-footer-item mark">© 2024 aposphäre - c/o IQVIA Commercial GmbH & Co. OHG</div>
        <div className='layout-footer-links'>
          <a className="layout-footer-item layout-footer-item-link" href="https://www.aposphaere.de/impressum/" target="_blank" rel="noopener noreferrer">
            {'Impressum'}
          </a>
          <span className="layout-divider">|</span>
          <a className="layout-footer-item layout-footer-item-link" href="https://www.aposphaere.de/datenschutz/" target="_blank" rel="noopener noreferrer">
            {'Datenschutz'}
           </a>
        </div>
      </div>
    </div>
  )
}

export default MainLayout;

import { gql } from 'graphql-request';

export const app = gql`
query($id: ID!) {
  app(id: $id) {
    id
    name
    imgPath
    link
    version {
      id
      name
      version
      size
      publishedOn
      qrCodeImgPath
      linkDownload
    }
  }  
}
`